<template>
  <div v-if="numbers" class="pay-page">
    <div class="pay-header">
      <div class="pay-header-name">
        <span class="pay-header-name-left">Hi {{ clientName }}</span>
        <span class="pay-header-name-right">您有共 {{ numbers }} 笔未缴账单</span>
      </div>
      <div class="pay-header-money">
        <span class="pbm-unit">¥</span>
        <span class="pbm-number">{{ arrearsAmount }}</span>
      </div>
    </div>
    <div class="pay-content">
      <user-card :dataInfo="dataInfo" ref="userCard"/>
    </div>
  </div>
</template>

<script>
import UserCard from './module/userCard';
import { selectPayOrderPage, paymentOrder } from '../../api/home';
import { Toast } from 'vant';
import { formatTime } from '@/utils/tools';
import { isAmount } from '@/utils/validator';
import NP from 'number-precision';

export default {
  data() {
    return {
      clientName: '',
      numbers: '',
      arrearsAmount: '',
      finalPaymentDate: '',
      checked: '',
      dataInfo: [],
      totalPreDeposit: 0,
    };
  },
  components: {
    UserCard,
  },
  mounted() {
    this.selectPayOrderPage();
  },
  methods: {
    async selectPayOrderPage() {
      const { status, resultData } = await selectPayOrderPage('');
      if (status === 'complete') {
        this.clientName = resultData.clientName;
        let amount = 0;
        resultData.billOrder.map((item) => {
          amount += item.orders.length;
        });
        this.numbers = amount;
        this.arrearsAmount = isAmount(resultData.arrearsAmount);
        this.finalPaymentDate = formatTime(resultData.finalPaymentDate);
        // 立即缴费按钮，如果都都选不置灰，反之都不勾选 置灰
        resultData.billOrder &&
          resultData.billOrder.map((item) => {
            item.isDisabled = false;
            // 判断余额是否大于0
            if (!isNaN(Number(item.depositAmount)) && Number(item.depositAmount) > 0) {
              item.isPrestore = false;
              item.depositType = true;
            } else {
              item.isPrestore = true;
              item.depositType = false;
            }

            // if (item.depositType) {
            //   let actual = NP.minus(Number(item.arrearsAmount), Number(item.depositAmount));
            //   if (actual > 0) {
            //     item.arrearsAmount = actual;
            //   } else {
            //     item.arrearsAmount = 0;
            //   }
            // }
          });
        this.dataInfo = resultData.billOrder;
        console.log('dataInfo========', this.dataInfo);
      }
    },
    // 微信支付：
    onBridgeReady(params) {
      var that = this;
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.appId, //公众号名称，由商户传入
          timeStamp: params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, //支付签名随机串，不长于 32 位
          package: params.packageValue, //统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign, //支付签名
        },
        function(res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            console.log('成功', res.err_msg);
            that.$router.push({
              name: 'PaymentResult',
              path: '/QueryPay/PaymentResult',
              query: {
                name: 'QueryPay',
              },
            });
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            console.log('失败', res.err_msg);
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.pay-header {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: hidden;
  background: url('../../assets/background/backdrop.png');
  background-size: 100% auto;
  &-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
    &-left {
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: -0.48px;
    }
    &-right {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: -0.42px;
    }
  }
  &-money {
    display: flex;
    align-items: center;
    margin: 0 16px;
    .pbm-unit {
      font-family: PingFangSC-Semibold;
      font-size: 38px;
      color: #ffffff;
      margin-right: 10px;
    }
    .pbm-number {
      font-family: DINAlternate-Bold;
      font-size: 38px;
      color: #ffffff;
    }
  }
}
.pay-content {
  margin: -190px 16px;
  border-radius: 8px;
  padding-bottom: 50px;
}
.pay-foot {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  &-mouth {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
  &-name {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
  }
  &-pay {
    background: #0a79ec;
    border-radius: 8px;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #ffffff;
    height: 40px;
  }
}
</style>
